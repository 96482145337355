<template>
  <div
    class="pa-4 grey lighten-3"
    style="height:calc(100% - 50px);overflow:auto"
  >
    <v-card v-for="(item, index) in reports" :key="index" class="mb-2">
      <v-img :src="item"> </v-img>
    </v-card>
    <v-card
      class="border_1 d-flex justify-center"
      flat
      tile
      height="100"
      @click="fileUpdate"
      v-if="reports.length < 3"
    >
      <v-icon size="40" color="primary">
        mdi-plus
      </v-icon>
    </v-card>
    <input
      type="file"
      id="btn_file"
      accept="image/*"
      @change="inputImage($event)"
      style="display:none"
    />
    <v-btn
      block
      color="primary"
      class="my-2"
      @click="commit"
      :loading="loading"
      :disabled="disabled"
    >
      <v-icon left>mdi-cloud-upload-outline</v-icon>
      上传
    </v-btn>
    <PreCheck />
  </div>
</template>
<script>
import lrz from "lrz";
import { mapState } from "vuex";
import PreCheck from "@/components/Local/PreCheck.vue";
export default {
  name: "UploadReport",
  components: { PreCheck },
  data: () => ({
    loading: false,
    reports: [],
    disabled: false,
  }),
  computed: {
    ...mapState(["currentUser"]),
  },
  methods: {
    fileUpdate() {
      document.getElementById("btn_file").click();
    },
    inputImage(e) {
      let img = e.target.files[0];
      if (img) {
        //this.option.img = img;
        //let url = URL.createObjectURL(img);
        lrz(img)
          .then((rst) => {
            // 处理成功会执行
            this.reports.push(rst.base64);
            this.isCropper = true;
          })
          .catch((err) => {
            console.error(err);
            this.$notify.error({
              message: err,
            });
            // 处理失败会执行
          })
          .always(() => {
            // 不管是成功失败，都会执行
          });
        // global.blobToDataURL(img, (base64Url) => {
        //   console.log(base64Url);
        //   this.reports.push(base64Url);
        //   this.isCropper = true;
        // });
      }
    },
    commit() {
      if (this.reports.length === 0) {
        this.$notify.warning({
          message: "至少上传一份报告！",
        });
      } else {
        this.save();
      }
    },
    save() {
      this.loading = true;
      this.$api
        .postAdd_Testsheet({
          IDCARD: this.currentUser.IDCARD,
          PNAME: this.currentUser.PNAME,
          PSEX: this.currentUser.PSEX,
          PAGE: this.currentUser.PAGE,
          TSIMAGEPATH1: this.reports[0],
          TSIMAGEPATH2: this.reports[1] || "",
          TSIMAGEPATH3: this.reports[2] || "",
        })
        .then((res) => {
          this.disabled = true;
          let data = JSON.parse(res);
          if (data.code !== 0) {
            this.$notify.error({
              message: data.message,
            });
            return;
          } else {
            this.$notify.success({
              message: data.message,
            });
          }
        })
        .catch((error) => {
          this.$notify.error({
            message: error,
          });
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.border_1 {
  border: 2px dashed #1976d2 !important;
}
</style>
